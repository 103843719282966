import { createThunkSlice } from 'src/redux/ReduxUtils';
import { RootState } from 'src/redux/rootReducerType';
import RentersDashboardService from 'src/services/RentersDashboard.service';
import TeamsService from 'src/services/Teams.service';
import parseQueryParams from 'src/utils/query';
import { enqueueSnackbar } from 'notistack';
import {
  ComplianceUser,
  ComplianceUsersResponse,
  RentersDashboardCertification,
} from 'src/types/Certification';
import CertificationService from 'src/services/Certification.service';
import { RentersDashboardCategories } from 'src/types/Categories';
import {
  createGridConfig,
  getGridConfig,
  applyNewColumnsState,
  updateGridConfig,
  ValidatedGrid,
} from 'src/services/AGGridConfiguration.service';
import { AGGridColumn, AGGridConfig } from 'src/types/AGGridConfig';
import { RentersDashboardColDefs } from 'src/components/RentersDashboard/ApplicantsTable/ApplicantsTableDefinitions';
import { GridApi, ColDef } from 'ag-grid-community';
import { PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isLoadingCertifications: boolean;
  isBulkCommunications: boolean;
  certifications: RentersDashboardCertification[];
  selectedCertifications: RentersDashboardCertification[];
  showOnlySelectedCertifications: boolean;
  certificationsError: string;
  complianceUsers: ComplianceUser[];
  isLoadingComplianceUsers: boolean;
  categories: { [key: number | string]: RentersDashboardCategories };
  colDefs: null | ColDef[];
  gridConfig: null | AGGridConfig;
  gridApi: GridApi | null;
  displayedRowCount: number | null;
}

const initialState: InitialState = {
  isLoadingCertifications: false,
  isBulkCommunications: false,
  certifications: [],
  selectedCertifications: [],
  showOnlySelectedCertifications: false,
  certificationsError: '',
  complianceUsers: [],
  isLoadingComplianceUsers: false,
  categories: {},
  colDefs: null,
  gridConfig: null,
  gridApi: null,
  displayedRowCount: 0,
};

const RentersDashboardSlice = createThunkSlice({
  name: 'RentersDashboard',
  initialState,
  reducers: (create) => {
    return {
      fetchRentersCertifications: create.asyncThunk(
        async (params: string, { dispatch }) => {
          const response =
            await RentersDashboardService.fetchRentersCertifications(params);

          let certCategoryIds: number[] = (response.data || []).map(
            (cert: RentersDashboardCertification) => cert.category_id
          );

          certCategoryIds = Array.from(new Set(certCategoryIds));

          if (certCategoryIds.length > 0) {
            await dispatch(fetchCertificationsCategories(certCategoryIds));
          }

          return response.data;
        },
        {
          options: {
            condition(arg, thunkApi) {
              const { rentersDashboard } = thunkApi.getState() as RootState;
              if (rentersDashboard.isLoadingCertifications === true) {
                return false;
              }
            },
          },
          pending: (state) => {
            state.isLoadingCertifications = true;
            state.certificationsError = '';
          },
          fulfilled: (state, action) => {
            state.isLoadingCertifications = false;
            state.certifications = action.payload;
          },
          rejected: (state, action) => {
            state.isLoadingCertifications = false;
            state.certifications = [];
            state.certificationsError = action.error.message ?? 'Unknown Error';
          },
        }
      ),
      fetchDashboardGrid: create.asyncThunk(
        async () => {
          const response = await getGridConfig('renters_dashboard_config');

          if (response.data.length > 0) {
            let gridConfig = response.data[0];

            const gridColumns = getColDefs(gridConfig);
            const payload = checkGridValidity(gridColumns, gridConfig);

            if (
              payload.modifiedColumns.length > 0 ||
              payload.deletedColumns.length > 0 ||
              payload.newColumns.length > 0
            ) {
              const newResponse = await applyNewColumnsState(
                gridConfig.id,
                payload
              );
              gridConfig = newResponse.data;
            }

            return gridConfig;
          }

          const payload = {
            name: 'renters_dashboard_config',
            columns: RentersDashboardColDefs.map(
              (column: ColDef, index: number) => ({
                name: column.headerName,
                field: column.field,
                order: index,
                visibility: !column.hide,
                width: column.minWidth || 120,
              })
            ),
          };
          const newConfig = await createGridConfig(payload);
          return newConfig.data || null;
        },
        {
          pending: (state) => {
            state.gridConfig = null;
          },
          fulfilled: (state, action) => {
            const gridConfig = action.payload;
            state.gridConfig = gridConfig;

            const sortedColDefs = getColDefs(gridConfig).sort(
              sortGridColumn(gridConfig)
            ) as unknown[];

            state.colDefs = sortedColDefs;
          },
          rejected: (state) => {
            state.gridConfig = null;
          },
        }
      ),
      fetchComplianceUsers: create.asyncThunk(
        async (id: number) => {
          const baseParams = {
            fields: ['members.id', 'members.first_name', 'members.last_name'],
            expand: ['members'],
          };

          const managerParams = `?${parseQueryParams({
            managers__id: id,
            ...baseParams,
          })}`;

          const memberParams = `?${parseQueryParams({
            members__id: id,
            ...baseParams,
          })}`;

          try {
            const managersResponse =
              await TeamsService.fetchComplianceTeamMembers(managerParams);

            const membersResponse =
              await TeamsService.fetchComplianceTeamMembers(memberParams);
            const response = processComplianceUsersData([
              ...(managersResponse.data || []),
              ...(membersResponse.data || []),
            ]);
            return response;
          } catch (error) {
            enqueueSnackbar({
              message: 'Error fetching compliance users',
              variant: 'error',
            });
            throw error;
          }
        },
        {
          options: {
            condition(arg, thunkApi) {
              const { rentersDashboard } = thunkApi.getState() as RootState;
              if (rentersDashboard.isLoadingComplianceUsers === true) {
                return false;
              }
            },
          },
          pending: (state) => {
            state.isLoadingComplianceUsers = true;
            state.complianceUsers = [];
          },
          fulfilled: (state, action) => {
            state.isLoadingComplianceUsers = false;
            state.complianceUsers = action.payload;
          },
          rejected: (state) => {
            state.isLoadingComplianceUsers = false;
            state.complianceUsers = [];
          },
        }
      ),
      fetchCertificationsCategories: create.asyncThunk(
        async (ids: number[]) => {
          try {
            const response = await CertificationService.fetchCategoriesInCerts(
              ids,
              parseQueryParams({
                expand: ['statuses'],
                fields: [
                  'id',
                  'statuses.id',
                  'statuses.is_approved',
                  'statuses.is_terminal',
                  'statuses.name',
                ],
              })
            );
            return response.data;
          } catch (error) {
            enqueueSnackbar({
              message: 'Error fetching compliance users',
              variant: 'error',
            });
            throw error;
          }
        },
        {
          fulfilled: (state, action) => {
            state.categories = action.payload.reduce(
              (accumulator, category) => {
                accumulator[category.id] = category.statuses;
                return accumulator;
              },
              {} as Record<number, RentersDashboardCategories>
            );
          },
          rejected: (state) => {
            state.categories = {};
          },
        }
      ),
      updateComplianceUser: create.asyncThunk(
        async ({
          certificationId,
          payload,
          params,
        }: ComplianceUpdateParams) => {
          try {
            const response = await CertificationService.updateCertification(
              certificationId,
              payload,
              params
            );
            return {
              certificationId,
              complianceUser: response.data.compliance_user,
            };
          } catch (error) {
            enqueueSnackbar({
              message: 'Error fetching compliance users',
              variant: 'error',
            });
            throw error;
          }
        },
        {
          fulfilled: (state, action) => {
            const { certificationId, complianceUser } = action.payload;
            const user = state.complianceUsers.find(
              (u) => u.id === complianceUser
            );
            const certificationIndex = state.certifications.findIndex(
              (cert) => cert.id === certificationId
            );

            if (certificationIndex !== -1) {
              state.certifications[certificationIndex] = {
                ...state.certifications[certificationIndex],
                compliance_user_id: complianceUser,
                compliance_user_name: user
                  ? `${user.first_name} ${user.last_name}`
                  : '',
              };
            }

            enqueueSnackbar('File Owner Updated', { variant: 'success' });
          },
          rejected: () => {
            enqueueSnackbar('An error happened updating the File Owner', {
              variant: 'error',
            });
          },
        }
      ),
      updateCertCategoryStatus: create.asyncThunk(
        async ({ payload, params = null }: UpdateCertCategoryStatusParams) => {
          try {
            const response =
              await CertificationService.updateCertCategoryStatus(
                payload,
                params
              );
            return response.data;
          } catch (error) {
            enqueueSnackbar({
              message: 'Error Updating Category Status',
              variant: 'error',
            });
            throw error;
          }
        },
        {
          fulfilled: (state, action) => {
            const { certification, status } = action.payload;
            const certIndex = state.certifications.findIndex(
              (cert) => cert.id === certification
            );
            const cert = state.certifications[certIndex];
            cert.is_terminal = status.is_terminal;
            cert.is_approved = status.is_approved;
            cert.status_name = status.name;
            cert.status_id = status.id;
            enqueueSnackbar('Certification Status Updated', {
              variant: 'success',
            });
          },
          rejected: () => {
            enqueueSnackbar(
              'An error happened updating the Certification Status',
              {
                variant: 'error',
              }
            );
          },
        }
      ),
      updateCertDeadlineDate: create.asyncThunk(
        async ({ payload }: UpdateCertDeadlineDatePayload) => {
          try {
            const response = await CertificationService.updateCertification(
              payload.certification,
              { deadline_date: payload.deadline_date }
            );
            return response.data;
          } catch (error) {
            enqueueSnackbar({
              message: 'Error updating deadline date',
              variant: 'error',
            });
            throw error;
          }
        },
        {
          fulfilled: (state, action) => {
            const { id, deadline_date } = action.payload;
            const certIndex = state.certifications.findIndex(
              (cert) => cert.id === id
            );
            const cert = state.certifications[certIndex];
            cert.deadline_date = deadline_date;
            enqueueSnackbar('Certification Deadline Date Updated', {
              variant: 'success',
            });
          },
          rejected: () => {
            enqueueSnackbar(
              'An error happened updating the Certification Deadline Date',
              {
                variant: 'error',
              }
            );
          },
        }
      ),
      reOrderColumns: create.asyncThunk(
        async ({ newIndex, colId }: reOrderColumnsParams, { getState }) => {
          const { rentersDashboard } = getState() as RootState;
          const columns = rentersDashboard.gridConfig?.columns || [];
          const oldIndex = columns.findIndex(
            (column) => column.field === colId
          );

          if (newIndex === -1 && oldIndex === -1) {
            throw new Error('Column not found');
          }

          const newColumns: AGGridColumn[] = JSON.parse(
            JSON.stringify(columns)
          );
          const removedColumn = newColumns.splice(oldIndex, 1)[0];
          newColumns.splice(newIndex, 0, removedColumn);
          newColumns.forEach((column, index) => {
            column.order = index;
          });
          const modifiedColumns = newColumns.slice(
            Math.min(newIndex, oldIndex),
            Math.max(newIndex, oldIndex) + 1
          );

          const result = await updateGridConfig(
            rentersDashboard.gridConfig.id,
            { columns: modifiedColumns }
          );

          return result.data;
        },
        {
          fulfilled: (state, action) => {
            const gridConfig = action.payload;

            state.gridConfig = gridConfig;
            state.colDefs = state.colDefs.sort(sortGridColumn(gridConfig));

            enqueueSnackbar('Columns ordered saved!', { variant: 'success' });
          },
          rejected: () => {
            enqueueSnackbar('An error happened updating the column order', {
              variant: 'error',
            });
          },
        }
      ),
      updateColumn: create.asyncThunk(
        async ({ colId, values }: UpdateColumnParams, { getState }) => {
          const { rentersDashboard } = getState() as RootState;
          const columns = rentersDashboard.gridConfig?.columns || [];
          const columnIndex = columns.findIndex((col) => col.field === colId);

          if (columnIndex === -1) {
            throw new Error('Column not found');
          }
          const needsUpdate = Object.keys(values).some(
            (key) => columns[columnIndex][key] !== values[key]
          );
          if (!needsUpdate) {
            return { noUpdate: true };
          }

          const result = await updateGridConfig(
            rentersDashboard.gridConfig.id,
            { columns: [{ ...columns[columnIndex], ...values }] }
          );

          return result.data;
        },
        {
          fulfilled: (state, action) => {
            if (action.payload.noUpdate) {
              return;
            }
            state.gridConfig = action.payload;
            enqueueSnackbar('Column successfully updated!', {
              variant: 'success',
            });
          },
          rejected: () => {
            enqueueSnackbar('An error happened updating the column', {
              variant: 'error',
            });
          },
        }
      ),
      setSelectedCertifications: create.reducer(
        (state, action: PayloadAction<RentersDashboardCertification[]>) => {
          state.selectedCertifications = action.payload;
        }
      ),
      clearSelectedCertifications: create.reducer((state) => {
        state.selectedCertifications = [];
      }),
      toggleShowSelectedCertifications: create.reducer((state) => {
        state.showOnlySelectedCertifications =
          !state.showOnlySelectedCertifications;
      }),
      setIsBulkCommunications: create.reducer(
        (state, action: PayloadAction<boolean>) => {
          state.isBulkCommunications = action.payload;
        }
      ),
      setDisplayedRowCount: create.reducer(
        (state, action: PayloadAction<number>) => {
          state.displayedRowCount = action.payload;
        }
      ),
      restoreGridConfig: create.reducer((state) => {
        state.gridConfig = null;
      }),
      setGridApi: create.reducer(
        (state, action: PayloadAction<GridApi | null>) => {
          state.gridApi = action.payload;
        }
      ),
      modifyGridColumVisibility: create.reducer(
        (state, action: PayloadAction<AGGridColumn>) => {
          const columnIndex = state.gridConfig.columns.findIndex(
            (col: AGGridColumn) => col.field === action.payload.field
          );
          if (columnIndex !== -1) {
            state.gridConfig.columns[columnIndex].visibility =
              action.payload.visibility;

            state.colDefs[columnIndex].hide = !action.payload.visibility;
          }
        }
      ),
      resetState: create.reducer(() => initialState),
    };
  },
});

export const {
  fetchRentersCertifications,
  fetchDashboardGrid,
  fetchComplianceUsers,
  fetchCertificationsCategories,
  updateComplianceUser,
  updateCertCategoryStatus,
  updateCertDeadlineDate,
  setSelectedCertifications,
  toggleShowSelectedCertifications,
  clearSelectedCertifications,
  setDisplayedRowCount,
  setIsBulkCommunications,
  setGridApi,
  modifyGridColumVisibility,
  reOrderColumns,
  updateColumn,
  resetState,
} = RentersDashboardSlice.actions;

export default RentersDashboardSlice.reducer;

interface ComplianceUpdateParams {
  certificationId: number;
  payload: { compliance_user: number };
  params?: string;
}

interface UpdateCertCategoryStatusParams {
  payload: { certification: number; status: number };
  params?: string;
}

interface UpdateCertDeadlineDatePayload {
  payload: { certification: string; deadline_date: string };
}

interface reOrderColumnsParams {
  newIndex: number;
  colId: string;
}

interface UpdateColumnParams {
  colId: string;
  values: Partial<AGGridColumn>;
}

const processComplianceUsersData = (
  data: ComplianceUsersResponse[]
): ComplianceUser[] => {
  const usersMap = new Map<number, ComplianceUser>();

  data.forEach((response) => {
    response.members.forEach((user) => {
      usersMap.set(user.id, user);
    });
  });

  return Array.from(usersMap.values());
};

const getColDefs = (gridConfig): ColDef[] =>
  RentersDashboardColDefs.map((column) => {
    const configColumn = gridConfig.columns.find(
      (configColumn) => configColumn.name === column.headerName
    );

    return {
      ...column,
      hide: configColumn ? !configColumn.visibility : column.hide,
      width: configColumn ? configColumn.width : column.width,
    };
  });

const findModifiedColumn = (columns: ColDef[], col: AGGridColumn): ColDef => {
  return columns.find(
    (c) =>
      (c.field === col.field && c.headerName !== col.name) ||
      (c.field !== col.field && c.headerName === col.name)
  );
};

const checkGridValidity = (
  columns: ColDef[],
  gridConfig: AGGridConfig
): ValidatedGrid => {
  const modifiedColumns = gridConfig.columns
    .filter((col) => findModifiedColumn(columns, col))
    .map((col) => {
      const newCol = findModifiedColumn(columns, col);
      return { ...col, name: newCol.headerName, field: newCol.field };
    });

  const deletedColumns = gridConfig.columns.filter(
    (col) =>
      !columns.find((c) => c.field === col.field || c.headerName === col.name)
  );

  const newColumns = columns
    .filter(
      (col) =>
        !gridConfig.columns.find(
          (c) => c.field === col.field || col.headerName === c.name
        )
    )
    .map((col) => {
      const index = columns.findIndex(
        (c) => c.field === col.field || c.headerName === col.headerName
      );
      return { ...col, order: index };
    });

  return { modifiedColumns, deletedColumns, newColumns };
};

const sortGridColumn =
  (gridConfig) =>
  (a, b): number => {
    const aConfig = gridConfig.columns.find((col) => col.field === a.field);
    const bConfig = gridConfig.columns.find(
      (configColumn) => configColumn.field === b.field
    );
    return aConfig.order - bConfig.order;
  };
