import { enqueueSnackbar } from 'notistack';
import { updateGridConfig } from 'src/services/AGGridConfiguration.service';

export const parseProgress = (progressString: string): number => {
  if (typeof progressString === 'string') {
    const cleanedString = progressString.replace('%', '').trim();
    return Number(cleanedString) || 0;
  }
  return Number(progressString) || 0;
};

export const currencyFormatter = (currency: number): string => {
  const currencyDec = currency ? currency.toFixed(2) : '';
  const formatted = currencyDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return currencyDec.length ? `$${formatted}` : null;
};

export const addThousandsComma = (number: number): string => {
  return number ? String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
};

export const dateComparator = (filterDate, cellValue): number => {
  const [year, month, day] = cellValue.split('-').map(Number);
  const cellUTC = Date.UTC(year, month - 1, day);
  const filterUTC = Date.UTC(
    filterDate.getFullYear(),
    filterDate.getMonth(),
    filterDate.getDate()
  );
  return cellUTC - filterUTC;
};

export const dateFormatter = (date: string): string => {
  if (!date) {
    return '';
  }

  const dateParts = date.split('-');
  if (dateParts.length !== 3) {
    return date;
  }
  const [year, month, day] = dateParts;
  return `${month}/${day}/${year}`;
};

export const clearFilterModelString = async (
  gridConfigId: number
): Promise<void> => {
  try {
    await updateGridConfig(gridConfigId, { filter_model_string: null });
    enqueueSnackbar('Filters cleared successfully', {
      variant: 'success',
    });
  } catch (e) {
    console.error('ERROR', e);
    enqueueSnackbar('Something went wrong clearing the filters', {
      variant: 'error',
    });
  }
};
