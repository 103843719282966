import axios from 'src/utils/axios';
import { AxiosResponse } from 'axios';
import { AGGridColumn, AGGridConfig } from 'src/types/AGGridConfig';
import { ColDef } from 'ag-grid-community';

export interface ValidatedGrid {
  modifiedColumns: AGGridColumn[];
  deletedColumns: AGGridColumn[];
  newColumns: ColDef[];
}

const baseURL = 'ag_grid_configuration';

export const getGridConfig = (name: string): Promise<AxiosResponse> => {
  return axios.get(`${baseURL}/?name=${name}`);
};

export const createGridConfig = (
  payload: Partial<AGGridConfig>
): Promise<AxiosResponse> => {
  return axios.post(`${baseURL}/`, payload);
};

export const updateGridConfig = (
  id: number,
  payload: Partial<AGGridConfig>
): Promise<AxiosResponse> => {
  return axios.patch(`${baseURL}/${id}/`, payload);
};

export const applyNewColumnsState = (
  id: number,
  payload: ValidatedGrid
): Promise<AxiosResponse> => {
  return axios.patch(`${baseURL}/${id}/apply_new_columns_state/`, payload);
};
