import React, { useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { updateCertDeadlineDate } from 'src/redux/slices/RentersDashboard/RentersDashboardSlice';
import { AppDispatch } from 'src/redux/store';
import { formatDatePost, fDisplayDate } from 'src/utils/formatTime';
import { RootState } from 'src/redux/rootReducerType';
import { Typography } from '@mui/material';

interface DatePickerCellEditorProps extends ICellEditorParams {
  certificationId: string;
}

const DatePickerCellEditor: React.FC<DatePickerCellEditorProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { certificationId } = props;

  const isBulkCommunications = useSelector(
    (state: RootState) => state.rentersDashboard.isBulkCommunications
  );

  const [value, setValue] = useState<Date | null>(
    props.value ? new Date(props.value) : null
  );

  const handleDateChange = async (newDate: Date | null): Promise<void> => {
    setValue(newDate);
    if (newDate) {
      const dateToPost = formatDatePost(newDate);
      const payload = {
        certification: certificationId,
        deadline_date: dateToPost,
      };
      try {
        await dispatch(updateCertDeadlineDate({ payload }));
      } catch (error) {
        console.error('Failed to update deadline date:', error);
      }
    }
  };

  return isBulkCommunications ? (
    <Typography fontSize={13}>{fDisplayDate(value)}</Typography>
  ) : (
    <DesktopDatePicker
      value={value}
      inputFormat="MM/dd/yyyy"
      onChange={handleDateChange}
      onClose={props.stopEditing}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          InputProps={{
            ...params.InputProps,
            sx: { paddingHorizontal: 10 },
          }}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: 13,
              minHeight: 20,
            },
            '& .MuiButtonBase-root': {
              padding: '0 4px 0 0',
            },
            '& .MuiSvgIcon-root': {
              padding: '2px',
            },
          }}
        />
      )}
    />
  );
};

export default DatePickerCellEditor;
