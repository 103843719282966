import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
// guards
import GuestGuard from '../guards/GuestGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import { Outlet } from 'react-router';
import { DASHBOARD_GROUPS } from '../utils/dashboard';
import ComponentLoader from 'src/routes/ComponentLoader';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'reset-password/:uidb64/:token',
          element: <ResetPasswordConfirmation />,
        },
        {
          path: 'create-password/:uidb64/:token',
          element: <ResetPasswordConfirmation createPassword />,
        },
        { path: ':auth_link_code', element: <OneClickLogin /> },
      ],
    },

    { path: 'verify_email_address/:auth_email_code', element: <VerifyEmail /> },

    // Household Members Routes
    {
      path: 'user',
      element: (
        <RoleBasedGuard accessibleRoles={['resident']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        // Tenant Landing
        {
          children: [{ path: '', element: <TenantLanding /> }],
        },
        {
          path: 'add-invite',
          children: [
            {
              path: 'add-household-member',
              element: <InviteToHouseholdHolder />,
            },
          ],
        },
        {
          path: 'signatures',
          children: [
            { path: '', element: <Signatures /> },
            { path: ':form_id', element: <SignaturesDetail /> },
          ],
        },
        {
          path: 'questionnaire-completed',
          children: [
            { path: ':survey_id', element: <QuestionnaireCompleted /> },
          ],
        },
        {
          path: 'settings',
          children: [{ path: '', element: <MySettingsPage /> }],
        },
      ],
    },

    {
      path: 'questionnaire/:survey_id/:section_id/:page_id',
      element: (
        <RoleBasedGuard accessibleRoles={['resident']}>
          <Questionnaire />
        </RoleBasedGuard>
      ),
    },

    // Dashboard Routes
    {
      path: 'pronto-admin',
      element: (
        <RoleBasedGuard accessibleRoles={['compliance']}>
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: '', element: <Navigate to="/pronto-admin/forms" replace /> },
        // Pronto Housing Administration
        {
          path: 'forms',
          children: [
            { path: '', element: <FormList /> },
            { path: 'search/:search', element: <FormList /> },
            { path: 'new', element: <FormCreate /> },
            { path: ':id', element: <FormDetail /> },
            { path: ':id/edit', element: <FormCreate /> },
          ],
        },
        {
          path: 'questions',
          children: [
            { path: '', element: <QuestionList /> },
            { path: 'search/:search', element: <QuestionList /> },
            { path: 'new', element: <QuestionCreate /> },
            { path: ':id/edit', element: <QuestionCreate /> },
          ],
        },
        {
          path: 'certification-config',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                >
                  <CertificationConfigTemplateList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':certification_config_id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                >
                  <CertificationConfigTemplateWorkbench />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':certification_config_id/differences',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                >
                  <CertConfigDiff />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: '',
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                    >
                      <CertsList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ':child_id',
                  element: (
                    <RoleBasedGuard
                      accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                    >
                      <DiffsDetail />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'templates',
          children: [{ path: '', element: <DefaultTemplates /> }],
        },
        {
          path: 'contacts',
          children: [
            { path: '', element: <Contacts /> },
            {
              path: 'contacts-create/:contact_id/edit',
              element: <ContactCreate />,
            },
            { path: 'contacts-create', element: <ContactCreate /> },
          ],
        },
        {
          path: 'rules_templates',
          children: [{ path: '', element: <DynamicRuleTemplatesList /> }],
        },
        {
          path: 'demo_setup',
          children: [{ path: '', element: <DemoSetupPage /> }],
        },
      ],
    },

    // Compliance Routes
    { path: 'no-properties', element: <PageNoProperties /> },
    {
      path: 'workbench',
      children: [
        { path: 'setup', element: <PropertiesBoard /> },
        { path: 'setup/:property_slug', element: <PropertySetup /> },
        {
          path: 'upload_units/:property_slug',
          element: <UploadUnitsWizard />,
        },
        {
          path: 'upload_rent_roll/:property_slug',
          element: <UploadRentRollWizard />,
        },
      ],
    },
    {
      path: 'workbench',
      element: (
        <RoleBasedGuard
          accessibleRoles={['compliance', ...Object.values(DASHBOARD_GROUPS)]}
        >
          <DashboardLayout />
        </RoleBasedGuard>
      ),
      children: [
        { path: '', element: <PropertyPage /> },
        { path: 'app', element: <EmptyPage /> },
        { path: 'user/settings', element: <MySettingsPage /> },
        {
          path: 'my-renters',
          element: (
            <RoleBasedGuard
              accessibleRoles={[DASHBOARD_GROUPS.compliance_specialist]}
            >
              <ApplicantsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'team-renters',
          element: (
            <RoleBasedGuard
              accessibleRoles={[DASHBOARD_GROUPS.compliance_manager]}
            >
              <TeamApplicants />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user/summary-for-compliance-specialist',
          element: (
            <RoleBasedGuard
              accessibleRoles={[DASHBOARD_GROUPS.compliance_specialist]}
            >
              <UserDashboardPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user/my-tasks',
          element: (
            <RoleBasedGuard
              accessibleRoles={[
                DASHBOARD_GROUPS.compliance_specialist,
                DASHBOARD_GROUPS.compliance_manager,
              ]}
            >
              <UserDashboardPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user/team-tasks',
          element: (
            <RoleBasedGuard
              accessibleRoles={[
                DASHBOARD_GROUPS.compliance_manager,
                DASHBOARD_GROUPS.compliance_specialist,
              ]}
            >
              <UserDashboardPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user/communications',
          element: (
            <RoleBasedGuard
              accessibleRoles={[
                DASHBOARD_GROUPS.compliance_specialist,
                DASHBOARD_GROUPS.compliance_manager,
              ]}
            >
              <UserDashboardPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'bulk_communications',
          element: (
            <RoleBasedGuard
              accessibleRoles={[
                // DASHBOARD_GROUPS.compliance_specialist,
                // DASHBOARD_GROUPS.compliance_manager,
                DASHBOARD_GROUPS.bulk_communications,
              ]}
            >
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            {
              path: '',
              element: <BulkCommunicationsDashBoardPage />,
            },
            {
              path: 'create_bulk_communication',
              element: <CreateBulkCommunicationPage />,
            },
            {
              path: ':bulk_communication_id',
              element: <BulkCommunicationConfigurationPage />,
            },
          ],
        },
        {
          path: ':property_slug/certification-config/:certification_config_id',
          element: (
            <RoleBasedGuard
              accessibleRoles={[DASHBOARD_GROUPS.compliance_manager]}
            >
              <CertificationConfigWorkbench />
            </RoleBasedGuard>
          ),
        },
        {
          path: ':property_slug/certification-config/:certification_config_id/differences',
          element: (
            <RoleBasedGuard accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}>
              <CertConfigDiff />
            </RoleBasedGuard>
          ),
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                >
                  <CertsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':child_id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[DASHBOARD_GROUPS.pronto_admin]}
                >
                  <DiffsDetail />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: ':property_slug/certification-config',
          element: (
            <RoleBasedGuard accessibleRoles={['Certification Workbench']}>
              <CertificationConfigList />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user/:active_tab/communications/:conversationKey',
          element: <UserDashboardPage />,
        },
        {
          path: ':property_slug/communications/:conversationKey',
          element: <PropertyPage />,
        },
        { path: ':property_slug', element: <PropertyPage /> },
        {
          path: ':property_slug/:certification_id',
          element: <CertificationPage />,
        },
        {
          path: ':property_slug/:certification_id/communications/:conversationKey',
          element: <CertificationPage />,
        },
        // {path: ':property_slug/units/new', element: <UnitCreate/>},
        {
          path: ':property_slug/household/:household_id',
          element: <HouseholdPage />,
        },
        { path: ':property_slug/units/:unit_id', element: <UnitPage /> },
        {
          path: ':property_slug/create-questionnaire',
          element: <CreateQuestionnaire />,
        },
        {
          path: ':property_slug/add-rent-roll',
          element: <EmptyPage title="Add Rent Roll Form" />,
        },
        {
          path: ':property_slug/:certification_id/notifications',
          element: <EmptyPage title="Notifications" />,
        },
        {
          path: ':property_slug/notifications',
          element: <EmptyPage title="Notifications" />,
        },
      ],
    },

    { path: 'privacy-policy', element: <PrivacyPolicy /> },
    { path: 'cookies-policy', element: <CookiePolicy /> },
    { path: 'terms-of-use', element: <TermsOfUse /> },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = ComponentLoader(() => import('../pages/authentication/Login'));
const ResetPassword = ComponentLoader(() =>
  import('../pages/authentication/ResetPassword')
);
const ResetPasswordConfirmation = ComponentLoader(() =>
  import('../pages/authentication/ResetPasswordConfirmation')
);
const OneClickLogin = ComponentLoader(() =>
  import('../pages/authentication/OneClickLogin')
);
const VerifyEmail = ComponentLoader(() =>
  import('../pages/authentication/VerifyEmail')
);
// Main
const Page500 = ComponentLoader(() => import('../pages/Page500'));
const NotFound = ComponentLoader(() => import('../pages/Page404'));
const CookiePolicy = ComponentLoader(() => import('../pages/CookiePolicy'));
const PrivacyPolicy = ComponentLoader(() => import('../pages/PrivacyPolicy'));
const TermsOfUse = ComponentLoader(() => import('../pages/TermsOfUse'));

// Pronto Housing Administration

/* const ConfiguredProgramList = ComponentLoader(() => import('../pages/ProntoHousingAdministration/ConfiguredProgramList'));
 const ConfiguredProgramCreate = ComponentLoader(() => import('../pages/ProntoHousingAdministration/ConfiguredProgramCreate'));
 const ConfiguredProgramDetail = ComponentLoader(() => import('../pages/ProntoHousingAdministration/ConfiguredProgramDetail/ConfiguredProgramDetail')); */

// const QuestionMapping = ComponentLoader(lazy(() =>
// import('../components/ProntoHousingAdministration/QuestionMapping/QuestionMapping')));

// certification config
const CertificationConfigList = ComponentLoader(() =>
  import('../components/CertificationConfiguration/CertificationConfigList')
);
const CertificationConfigWorkbench = ComponentLoader(() =>
  import('../components/Compliance/Property/CertificationConfigWorkbench')
);
const CertificationConfigTemplateList = ComponentLoader(() =>
  import(
    '../pages/ProntoHousingAdministration/CertificationConfig/CertificationConfigTemplateList'
  )
);
const CertificationConfigTemplateWorkbench = ComponentLoader(() =>
  import(
    '../components/Compliance/Property/CertificationConfigTemplateWorkbench'
  )
);
const CertConfigDiff = ComponentLoader(() =>
  import('../components/Compliance/CertConfigDiff/CertConfigDiff')
);
const CertsList = ComponentLoader(() =>
  import('../components/Compliance/CertConfigDiff/CertsList')
);
const DiffsDetail = ComponentLoader(() =>
  import('../components/Compliance/CertConfigDiff/DiffsDetail')
);

const FormList = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/FormList')
);
const FormCreate = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/FormCreate')
);
const FormDetail = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/FormDetail')
);

const QuestionList = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/QuestionList')
);
const QuestionCreate = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/QuestionCreate')
);

const DefaultTemplates = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/DefaultTemplates')
);
const Contacts = ComponentLoader(() =>
  import('../components/ProntoHousingAdministration/Contacts/list/ContactList')
);
const ContactCreate = ComponentLoader(() =>
  import('../pages/Contact/ContactCreate')
);

const DynamicRuleTemplatesList = ComponentLoader(() =>
  import(
    '../components/ProntoHousingAdministration/DynamicRuleTemplates/list/DynamicRuleTemplatesList'
  )
);
const DemoSetupPage = ComponentLoader(() =>
  import('../pages/ProntoHousingAdministration/DemoSetup/DemoSetupPage')
);

// Pronto Housing Household Members
const TenantLanding = ComponentLoader(() =>
  import('../pages/tenant/TenantHomePage')
);
const InviteToHouseholdHolder = ComponentLoader(() =>
  import('../pages/tenant/inviteToHousehold/InviteToHouseholdHolder')
);
const QuestionnaireCompleted = ComponentLoader(() =>
  import('../pages/tenant/Questionnaire/QuestionnaireCompleted')
);
const MySettingsPage = ComponentLoader(() =>
  import('../pages/tenant/MySettings/MySettingsPage')
);

// Pronto Housing Questionnaire
const Questionnaire = ComponentLoader(() =>
  import('../pages/tenant/QuestionnaireV4/Questionnaire')
);

// Compliance Manager
const PropertyPage = ComponentLoader(() =>
  import('../pages/Compliance/PropertyPage')
);
const UnitPage = ComponentLoader(() =>
  import('../pages/Compliance/Unit/UnitPage')
);
const CreateQuestionnaire = ComponentLoader(() =>
  import('../pages/Compliance/CreateQuestionnaire')
);
const CertificationPage = ComponentLoader(() =>
  import('../pages/Compliance/CertificationPage')
);
const HouseholdPage = ComponentLoader(() =>
  import('../pages/Compliance/HouseholdPage')
);
// const UnitCreate = ComponentLoader(() => import('../pages/Compliance/Unit/UnitCreate'));
const ApplicantsPage = ComponentLoader(() =>
  import('../pages/Compliance/ApplicantsPage')
);
const TeamApplicants = ComponentLoader(() =>
  import('src/pages/Compliance/TeamApplicants')
);

// User
const UserDashboardPage = ComponentLoader(() =>
  import('../pages/UserDashboard/UserDashboardPage')
);

const BulkCommunicationsDashBoardPage = ComponentLoader(() =>
  import('../pages/BulkCommunications/BulkCommunicationsDashboard')
);

const CreateBulkCommunicationPage = ComponentLoader(() =>
  import('../pages/BulkCommunications/CreateBulkCommunication')
);

const BulkCommunicationConfigurationPage = ComponentLoader(() =>
  import('../pages/BulkCommunications/BulkCommunicationConfiguration')
);

// Property Setup
const PropertiesBoard = ComponentLoader(() =>
  import('../pages/PropertiesBoard/PropertiesBoardPage')
);
const PropertySetup = ComponentLoader(() =>
  import('../pages/PropertySetup/PropertySetupPage')
);
const UploadUnitsWizard = ComponentLoader(() =>
  import('../pages/PropertySetup/UploadUnitsWizard')
);
const UploadRentRollWizard = ComponentLoader(() =>
  import('../pages/PropertySetup/UploadRentRollWizard')
);

const EmptyPage = ComponentLoader(() => import('../pages/EmptyPage'));
const PageNoProperties = ComponentLoader(() =>
  import('../pages/PageNoProperties')
);
const Signatures = ComponentLoader(() =>
  import('src/components/QuestionnaireSummary/RequestedSignatures/Signatures')
);
const SignaturesDetail = ComponentLoader(() =>
  import(
    'src/components/QuestionnaireSummary/RequestedSignatures/SignaturesDetail'
  )
);
