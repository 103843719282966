import React, { FC, ReactNode, useEffect, useState } from 'react';
import parseQueryParams from 'src/utils/query';
import { updateCertCategoryStatus } from 'src/redux/slices/RentersDashboard/RentersDashboardSlice';
import MenuItem from '@mui/material/MenuItem';
import AGDropdownSelector from './AGDropdownSelector';
import { RootState } from 'src/redux/rootReducerType';
import { shallowEqual, useSelector } from 'react-redux';
import AGCategoryStatusLock from './AGCategoryStatusLock';
import { Stack, Tooltip, Typography } from '@mui/material';

export interface AGCategoryStatusProps {
  categoryId: number;
  certificationId: number;
  statusId: number;
  statusName: string;
  isApproved: boolean;
  isTerminal: boolean;
}

const AGCategoryStatus: FC<AGCategoryStatusProps> = ({
  categoryId,
  certificationId,
  statusId,
  statusName,
  isApproved,
  isTerminal,
}) => {
  const categories = useSelector(
    (state: RootState) => state.rentersDashboard.categories,
    shallowEqual
  );
  const [disabled, setDisabled] = useState(isTerminal);

  const options = categories[categoryId] || [];

  const isBulkCommunications = useSelector(
    (state: RootState) => state.rentersDashboard.isBulkCommunications
  );

  const certStatusChange = (
    dispatch,
    certificationId,
    value
  ): Promise<void> => {
    const payload = {
      certification: certificationId,
      status: value,
    };

    const params = parseQueryParams({
      expand: ['status'],
      fields: [
        'certification',
        'status.id',
        'status.is_terminal',
        'status.is_approved',
        'status.name',
      ],
    });

    return dispatch(
      updateCertCategoryStatus({
        payload,
        params,
      })
    );
  };

  const optionMapper = (option): ReactNode => (
    <MenuItem sx={{ fontSize: 13 }} key={`${option.id}-key`} value={option.id}>
      {option.name}
    </MenuItem>
  );

  useEffect(() => setDisabled(isTerminal), [isTerminal]);

  return isBulkCommunications ? (
    <Typography fontSize={13}>{statusName}</Typography>
  ) : (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      height="100%"
      gap="10px"
    >
      <Tooltip arrow placement="top" title={statusName}>
        <div className="ag-category-status-cell">
          <AGDropdownSelector
            certificationId={certificationId}
            initialValue={statusId}
            onValueChange={certStatusChange}
            options={options}
            optionMapper={optionMapper}
            disabled={disabled}
          />
        </div>
      </Tooltip>
      <AGCategoryStatusLock
        isApproved={isApproved}
        isTerminal={isTerminal}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    </Stack>
  );
};

export default AGCategoryStatus;
