import React from 'react';
import AGDropdownSelector from './AGDropdownSelector';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/redux/rootReducerType';
import { ComplianceUser } from 'src/types/Certification';
import parseQueryParams from 'src/utils/query';
import { updateComplianceUser } from 'src/redux/slices/RentersDashboard/RentersDashboardSlice';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';

export interface AGFileOwnerProps {
  certificationId: number;
  complianceUserName: string;
}

const AGFileOwner: React.FC<AGFileOwnerProps> = ({
  certificationId,
  complianceUserName,
}) => {
  const complianceUsers = useSelector(
    (state: RootState) => state.rentersDashboard.complianceUsers,
    shallowEqual
  );

  const isBulkCommunications = useSelector(
    (state: RootState) => state.rentersDashboard.isBulkCommunications
  );

  const complianceUserOnChange = (
    dispatch,
    certificationId,
    value
  ): Promise<void> => {
    const queryParams = parseQueryParams({
      fields: ['compliance_user.id'],
    });
    const complianceUserToChange = complianceUsers.find(
      (el) => `${el.first_name} ${el.last_name}` === value
    );
    const payload = { compliance_user: complianceUserToChange?.id || null };

    return dispatch(
      updateComplianceUser({
        certificationId,
        payload,
        params: queryParams,
      })
    );
  };

  const complianceUserOptionMapper = (
    option: ComplianceUser
  ): React.ReactNode => (
    <MenuItem
      sx={{ fontSize: 13 }}
      key={`${option.id}-compliance-user`}
      value={`${option.first_name} ${option.last_name}`}
    >
      {`${option.first_name} ${option.last_name}`}
    </MenuItem>
  );

  return isBulkCommunications ? (
    <Typography fontSize={13}>{complianceUserName}</Typography>
  ) : (
    <AGDropdownSelector
      allowNullValues
      certificationId={certificationId}
      initialValue={complianceUserName}
      onValueChange={complianceUserOnChange}
      options={complianceUsers}
      optionMapper={complianceUserOptionMapper}
    />
  );
};

export default AGFileOwner;
