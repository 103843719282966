import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grow,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, FormikProps, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import Logo from 'src/components/Logo';
import { formatPhoneNumber } from 'src/utils/formatPhoneNumber';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import axios from 'src/utils/axios';
import useAuth from 'src/hooks/useAuth';
import { enqueueSnackbar } from 'notistack';

interface FormValues {
  username: string;
  password: string;
}

export interface LoginFormV2Props {
  formik: FormikProps<FormValues>;
  formStep: LOGIN_FORM_STEP;
  setLoginFormStep: React.Dispatch<React.SetStateAction<LOGIN_FORM_STEP>>;
  extendSession?: boolean;
  loginError?: string;
}

enum INPUT_TYPES {
  EMAIL = 'email',
  PHONE = 'phone',
}

export const phoneRegex =
  /^(\([0-9]{3}\)|[0-9]{3})([\s.-]?)[0-9]{3}([\s.-]?)[0-9]{4}$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;

export enum LOGIN_FORM_STEP {
  'LOGIN' = 'LOGIN',
  'RESET_PASSWORD' = 'RESET_PASSWORD',
  'VERIFY_CODE' = 'VERIFY_CODE',
  'LOGIN_LINK_SENT' = 'LOGIN_LINK_SENT',
}

interface userLoginOption {
  value: string;
}

const LoginFormV2: FC<LoginFormV2Props> = ({
  formik,
  formStep,
  setLoginFormStep,
  extendSession,
  loginError,
}) => {
  const { user } = useAuth();
  const inputRef = useRef(null);
  const userLoginOptions = useMemo((): userLoginOption[] => {
    if (!user || !extendSession) {
      return [];
    }

    const options: userLoginOption[] = [
      {
        value: user.email as string,
      },
    ];
    (user.phones || []).forEach((phone: { phone_number: string }) => {
      options.push({
        value: formatPhoneNumber(phone?.phone_number?.replace('+1', '')),
      });
    });

    return options;
  }, [extendSession, user]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = formik;

  const { username, password } = values;

  const [isEmail, setIsEmail] = useState(emailRegex.test(username || ''));
  const [isPhone, setIsPhone] = useState(phoneRegex.test(username || ''));
  const [isAutofilled, setIsAutofilled] = useState(false);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const [inputType, setInputType] = useState<INPUT_TYPES>(
    (isNaN(Number(username || null)) || !username) &&
      !phoneRegex.test(username || null)
      ? INPUT_TYPES.EMAIL
      : INPUT_TYPES.PHONE
  );

  const [showPassword, setShowPassword] = useState(false);
  const [requestErrors, setRequestErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const autocompleteValue = useMemo(
    () => userLoginOptions.find((item) => item.value === username) || null,
    [userLoginOptions, username]
  );

  const handleUserFieldChange = useCallback(
    (inputValue: string): void => {
      setIsAutofilled(false);
      let value = inputValue;
      const isEmail = emailRegex.test(value);
      const isPhone = phoneRegex.test(value);

      if ((isNaN(Number(value)) && !isPhone) || value === '') {
        setInputType(INPUT_TYPES.EMAIL);
      } else {
        value = formatPhoneNumber(value);
        setInputType(INPUT_TYPES.PHONE);
      }

      void setFieldValue('username', value);
      setIsEmail(isEmail);
      setIsPhone(isPhone);
    },
    [setFieldValue]
  );

  const toggleShowPassword = useCallback((): void => {
    setShowPassword((prevState) => !prevState);
  }, []);

  const validatePhone = useCallback(async (): Promise<void> => {
    setRequestErrors(null);
    setIsLoading(true);
    try {
      const phoneWithUSCodeLength = 12;
      const usPrefix = '+1';

      let phone = username.replace(/[^\d+]/g, '');
      if (
        !(phone.length === phoneWithUSCodeLength || phone.startsWith(usPrefix))
      ) {
        phone = `+1${phone}`;
      }
      const response = await axios.post(
        'mobile_phone/mobile_phone_login/send_sms_code/',
        { phone }
      );
      if (response.data === 'SMS sent') {
        setLoginFormStep(LOGIN_FORM_STEP.VERIFY_CODE);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setRequestErrors('Phone number does not have a user associated.');
      console.error(error);
    }
  }, [setLoginFormStep, username]);

  const sendLoginLinkHandler = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await axios.post('pronto_auth/auth_link/login/', {
        email: username,
      });
      setLoginFormStep(LOGIN_FORM_STEP.LOGIN_LINK_SENT);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      enqueueSnackbar('There was an error sending login link', {
        variant: 'error',
      });
    }
  };

  const buttonLabel = useMemo(() => {
    if (isPhone) {
      return 'Send Code';
    }
    if (isEmail) {
      return 'Login';
    }
    return 'Continue';
  }, [isEmail, isPhone]);

  useEffect(() => {
    // Google Chrome handles autofill differently than other browsers
    // This is a workaround to detect autofill in Chrome
    // and enable the password field and login button
    // This is very much a hack but this is a known issue with Chrome
    // Solution modeled after this article https://medium.com/weekly-webtips/detecting-chrome-autofill-on-page-load-78ea178e4a68
    const detectAutofill = (element: Element): Promise<boolean> => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            window
              .getComputedStyle(element, null)
              .getPropertyValue('appearance') === 'menulist-button'
          );
        }, 100);
      });
    };

    const testAutofill = async (): Promise<void> => {
      const result = await detectAutofill(inputRef.current);
      if (result) {
        setIsEmail(true);
        setIsAutofilled(true);
      }
    };

    if (inputRef.current) {
      void testAutofill();
    }
  }, []);

  return (
    <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
      <Box sx={{ flexGrow: 1 }}>
        {!extendSession && (
          <>
            <Logo
              sx={{ marginBottom: '45px', marginLeft: '-5px' }}
              width={200}
            />
            <Typography variant="h4" gutterBottom>
              Login
            </Typography>
          </>
        )}
        {(requestErrors || loginError) && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {requestErrors}
            {loginError}
          </Alert>
        )}

        <FormikProvider value={formik}>
          <Form autoComplete="on" onSubmit={handleSubmit} role="form">
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {formStep === LOGIN_FORM_STEP.LOGIN && (
                <Stack flexDirection={'row'} gap={'10px'} alignItems="center">
                  {extendSession && (
                    <Autocomplete
                      fullWidth
                      onChange={(_, option) => {
                        handleUserFieldChange(option?.value || null);
                      }}
                      sx={{ mt: 1 }}
                      size="medium"
                      value={autocompleteValue}
                      options={userLoginOptions}
                      getOptionLabel={(option: userLoginOption) => option.value}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Email or Phone Number"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                  )}
                  {!extendSession && (
                    <TextField
                      sx={{ mt: 1 }}
                      label="Enter Email or Phone Number"
                      name="username"
                      type="text"
                      value={username}
                      autoComplete={inputType}
                      inputRef={inputRef}
                      InputLabelProps={{
                        shrink:
                          isAutofilled ||
                          Boolean(username) ||
                          isUsernameFocused,
                      }}
                      inputProps={{
                        maxLength: inputType === INPUT_TYPES.PHONE ? 11 : 300,
                      }}
                      onPaste={(evt) => {
                        evt.preventDefault();
                        const pasteValue = evt.clipboardData
                          ?.getData('Text')
                          ?.trim();
                        if (!pasteValue) {
                          return;
                        }
                        handleUserFieldChange(pasteValue);
                      }}
                      onChange={(event) =>
                        handleUserFieldChange(event.target.value)
                      }
                      onBlur={(event) => {
                        setIsUsernameFocused(false);
                        handleBlur(event);
                      }}
                      onFocus={() => setIsUsernameFocused(true)}
                      error={touched.username && Boolean(errors.username)}
                      helperText={touched.username && errors.username}
                      fullWidth
                    />
                  )}
                </Stack>
              )}
              <Grow in={isEmail}>
                <Stack
                  alignItems="flex-end"
                  justifyContent="center"
                  sx={{ mt: 1, display: isEmail ? 'flex' : 'none' }}
                >
                  <TextField
                    label={'Password'}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChange}
                    onBlur={(event) => {
                      setIsPasswordFocused(false);
                      handleBlur(event);
                    }}
                    onFocus={() => setIsPasswordFocused(true)}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    InputLabelProps={{
                      shrink:
                        isAutofilled || Boolean(password) || isPasswordFocused,
                    }}
                    InputProps={{
                      autoComplete: 'current-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={toggleShowPassword}
                            edge="end"
                            size="large"
                          >
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <Button
                    variant={'text'}
                    disabled={isSubmitting}
                    onClick={() =>
                      setLoginFormStep(LOGIN_FORM_STEP.RESET_PASSWORD)
                    }
                  >
                    Forgot your password?
                  </Button>
                </Stack>
              </Grow>

              <LoadingButton
                fullWidth
                sx={{ textTransform: 'none' }}
                type={isEmail ? 'submit' : 'button'}
                variant="contained"
                onClick={() => (isPhone ? validatePhone() : handleSubmit())}
                color="primary"
                size="large"
                disabled={(!isEmail && !isPhone) || isSubmitting}
                loading={isLoading || isSubmitting}
              >
                {buttonLabel}
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Box>
    </Stack>
  );
};

export default LoginFormV2;
