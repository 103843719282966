import { createSlice } from '@reduxjs/toolkit';

const COMMUNICATION_TYPES = {
  EMAIL: 1,
  SMS: 2,
  BOTH: 3,
};
const selectionCriteriaSlice = createSlice({
  name: 'Selection Criteria',
  initialState: {
    initialLoad: true,
    search: '',
    displayLoader: true,
    applicantsList: [],
    enabledCertificationsIds: [],
    applicantsFilters: {},
    order: '',
    orderBy: '',
    page: 1,
    pageSize: 50,
    applicantsCount: 0,
    selectedFilters: {},
    selectedCertifications: [],
    selectedCertificationIds: [],
    certificationFilters: {},
    saveCertifications: false,
    selectedHouseholdsExpanded: true,
  },
  reducers: {
    setDisplayLoader: (state, action) => {
      state.displayLoader = action.payload;
    },
    onOpenSetSelectedCertifications: (state, action) => {
      const selectedCertifications = action.payload;
      const selectedCertificationIds = selectedCertifications.map(
        (cert) => cert.id
      );
      state.selectedCertifications = selectedCertifications;
      state.selectedCertificationIds = selectedCertificationIds;
    },
    onApplicantsFetchCompleted: (state) => {
      state.initialLoad = false;
      state.displayLoader = false;
    },
    onApplicantsFetched: (state, action) => {
      let { applicantsList } = action.payload;
      const { applicantsCount, communicationType } = action.payload;
      applicantsList = sortListAndSetValidity(
        communicationType,
        applicantsList
      );
      state.applicantsList = applicantsList;
      state.enabledCertificationsIds = getEnabledCertificationsIds(
        applicantsList || []
      );
      state.applicantsCount = applicantsCount;
    },
    onFiltersFetched: (state, action) => {
      state.applicantsFilters = action.payload;
    },
    onFiltersApplied: (state, action) => {
      state.selectedFilters = action.payload;
    },
    onHeaderSorted: (state, action) => {
      const { order, orderBy } = action.payload;
      state.order = order;
      if (orderBy) {
        state.orderBy = orderBy;
      }
    },
    onPageChanged: (state, action) => {
      state.page = action.payload;
    },
    onRowsPerPageChanged: (state, action) => {
      state.pageSize = action.payload;
    },
    onClearSelectedCertifications: (state) => {
      state.selectedCertifications = [];
      state.selectedCertificationIds = [];
    },
    onToggleSelectAllCertifications: (state) => {
      const { selectedCertifications, selectedCertificationIds } =
        calculateSelectedCertifications(state);
      state.selectedCertifications = selectedCertifications;
      state.selectedCertificationIds = selectedCertificationIds;
    },
    onToggleSelectCertification: (state, action) => {
      const certification = action.payload;
      const index = state.selectedCertifications.findIndex(
        (selectedCertification) => selectedCertification.id === certification.id
      );
      if (index !== -1) {
        state.selectedCertifications.splice(index, 1);
        state.selectedCertificationIds.splice(index, 1);
      } else {
        state.selectedCertifications.push(certification);
        state.selectedCertificationIds.push(certification.id);
      }
    },
    onSetSaveCertifications: (state, action) => {
      state.saveCertifications = action.payload;
    },
    setSelectedHouseholdsExpanded: (state, action) => {
      state.selectedHouseholdsExpanded = action.payload;
    },
    resetSlice: (state) => {
      state.initialLoad = true;
      state.search = '';
      state.displayLoader = true;
      state.applicantsList = [];
      state.enabledCertificationsIds = [];
      state.applicantsFilters = {};
      state.order = '';
      state.orderBy = '';
      state.page = 1;
      state.pageSize = 50;
      state.applicantsCount = 0;
      state.selectedFilters = {};
      state.selectedCertifications = [];
      state.selectedCertificationIds = [];
      state.certificationFilters = {};
      state.saveCertifications = false;
    },
  },
});

const sortListAndSetValidity = (communicationType, applicantsList) => {
  const validator = getCertificationValidator(communicationType);
  applicantsList.forEach((certification, index) => {
    applicantsList[index].enabled =
      !certification?.is_terminal && validator(certification);
  });
  applicantsList = applicantsList.sort((a, b) => {
    if (a.enabled && !b.enabled) {
      return -1;
    } else if (!a.enabled && b.enabled) {
      return 1;
    }
    return 0;
  });
  return applicantsList;
};

const calculateSelectedCertifications = (state) => {
  const missingItems = getEnabledCertifications(state.applicantsList).filter(
    (cert) => !state.selectedCertificationIds.includes(cert.id)
  );

  let selectedCertifications;
  if (missingItems.length === 0) {
    selectedCertifications = state.selectedCertifications.filter(
      (certification) =>
        !state.enabledCertificationsIds.includes(certification.id)
    );
  } else {
    selectedCertifications = [...state.selectedCertifications];
    missingItems.forEach((certification) =>
      selectedCertifications.push(certification)
    );
  }
  const selectedCertificationIds = selectedCertifications.map(
    (cert) => cert.id
  );
  return { selectedCertifications, selectedCertificationIds };
};

const getEnabledCertifications = (certifications) => {
  return certifications.filter((cert) => cert.enabled);
};
const getEnabledCertificationsIds = (certifications) => {
  return getEnabledCertifications(certifications).map((cert) => cert.id);
};

const getCertificationValidator = (communicationType) => {
  let isValidFunction;

  switch (communicationType) {
    case COMMUNICATION_TYPES.EMAIL:
      isValidFunction = hasValidEmail;
      break;
    case COMMUNICATION_TYPES.SMS:
      isValidFunction = hasValidSMS;
      break;
    case COMMUNICATION_TYPES.BOTH:
      isValidFunction = hasEmailOrSMS;
      break;
  }
  return isValidFunction;
};
const hasEmailOrSMS = (certification) => {
  return hasValidEmail(certification) || hasValidSMS(certification);
};

const hasValidEmail = (certification) => {
  return (certification.household_email_addresses || []).length > 0;
};
const hasValidSMS = (certification) => {
  return (certification.household_mobile_phones || []).length > 0;
};
export const {
  setDisplayLoader,
  onOpenSetSelectedCertifications,
  onApplicantsFetchCompleted,
  onApplicantsFetched,
  onFiltersFetched,
  onFiltersApplied,
  onHeaderSorted,
  onPageChanged,
  onRowsPerPageChanged,
  onClearSelectedCertifications,
  onToggleSelectAllCertifications,
  onToggleSelectCertification,
  onSetSaveCertifications,
  setSelectedHouseholdsExpanded,
  resetSlice,
} = selectionCriteriaSlice.actions;
export default selectionCriteriaSlice.reducer;
