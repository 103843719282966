import React, { FC, ReactNode, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/redux/store';
import { CircularProgress } from '@mui/material';

export interface AGDropdownSelectorProps {
  allowNullValues?: boolean;
  certificationId: number;
  initialValue: number | string;
  onValueChange: (
    dispatch: typeof useDispatch<AppDispatch>,
    certificationId: number,
    value: number | string
  ) => Promise<void>;
  options: unknown[];
  optionMapper?: (option: unknown) => ReactNode;
  disabled?: boolean;
}

const AGDropdownSelector: FC<AGDropdownSelectorProps> = ({
  allowNullValues = false,
  certificationId,
  initialValue,
  onValueChange,
  options,
  optionMapper,
  disabled = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(initialValue);

  const handleEvent = (event): void => {
    event.preventDefault();

    setIsSubmitting(true);
    onValueChange(dispatch, certificationId, event.target.value)
      .then(() => {
        setValue(event.target.value);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const defaultOptionMapper = (option, index): ReactNode => (
    <MenuItem key={option + index} value={option} sx={{ fontSize: 12 }}>
      {option}
    </MenuItem>
  );

  return (
    <FormControl fullWidth>
      {isSubmitting ? (
        <div className="ag-dropdown-selector">
          <CircularProgress size={24} />
        </div>
      ) : (
        <Select
          size="small"
          sx={{
            fontSize: 12,
            height: 34,
          }}
          onChange={(event) => handleEvent(event)}
          value={`${value}`}
          disabled={isSubmitting || disabled}
        >
          {allowNullValues && (
            <MenuItem sx={{ fontSize: 12 }} selected value={0}>
              -------
            </MenuItem>
          )}
          {options?.length > 0 &&
            options.map(optionMapper || defaultOptionMapper)}
        </Select>
      )}
    </FormControl>
  );
};

export default AGDropdownSelector;
